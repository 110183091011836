import { Route, Routes, BrowserRouter } from "react-router-dom"
import { PublicRoute, PrivateRoute } from "router/RouteType"
import PrivateContainer from "containers/PrivateContainer"
// import Multiclient from 'components/Multiclient';
import { Env, MultiClient } from "config"
import LoadScreen from "screens/LoadScreen/LoadScreen"
import { CLIENT } from "config/init"

import AuthScreenClassic from "screens/LoginScreen/LoginScreen"
import AuthScreenEntel from "overrides/theme/entel/base/screens/LoginScreen/LoginScreen"
import { Suspense } from "react"

const PublicRouter = () => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const getAuthScreen = (group: CLIENT) => {
    switch (group) {
      case CLIENT.ENTEL:
        return AuthScreenEntel
      default:
        return AuthScreenClassic
    }
  }

  let AuthScreen = getAuthScreen(_client)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="login/*"
          element={
            <PublicRoute>
              <Routes>
                <Route
                  path="/*"
                  element={
                    <Suspense fallback={<></>}>
                      <AuthScreen />
                    </Suspense>
                  }
                />
              </Routes>
            </PublicRoute>
          }
        />
        <Route
          path="callback"
          element={
            <PublicRoute>
              <LoadScreen />
            </PublicRoute>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <PrivateContainer />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default PublicRouter
